import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DialButton from './DialButton'; // Import DialButton component

const initialState = {
  name: "",
  email: "",
  phone: "",  // Add phone to the initial state
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, phone, message }, setState] = useState(initialState);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);

    emailjs
      .sendForm(
        "service_vkdsqyw",  // Replace with your Service ID
        "template_mqwofw4", // Replace with your Template ID
        e.target,
        "PnzSXC0m-pVVW7jav"   // Replace with your Public Key
      )
      .then(
        (result) => {
          toast.success("Email sent successfully!");
          e.target.reset(); // Reset the form in the DOM
          clearState(); // Clear the state
          setIsSubmitting(false);
        },
        (error) => {
          toast.error("Failed to send email. Please try again.");
          setIsSubmitting(false);
        }
      );
  };


  return (
    <div>
      <ToastContainer />
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Contact us</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
                <DialButton phoneNumber="+8479994348" />
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
  <div className="form-row">
    <div className="form-group">
      <input
        type="text"
        id="name"
        name="from_name"
        className="form-control"
        placeholder="Name *"
        required
        onChange={handleChange}
      />
    </div>

    <div className="form-group">
      <input
        type="email"
        id="email"
        name="from_email"
        className="form-control"
        placeholder="Email *"
        required
        onChange={handleChange}
      />
    </div>

    <div className="form-group">
      <input
        type="tel"
        id="phone"
        name="phone"
        className="form-control"
        placeholder="Phone"
        onChange={handleChange}
      />
    </div>
  </div>

  <div className="form-group">
    <textarea
      name="message"
      id="message"
      className="form-control"
      rows="6"
      placeholder="Message *"
      required
      onChange={handleChange}
    />
  </div>

  <button type="submit" className="btn btn-custom btn-lg" disabled={isSubmitting}>
    {isSubmitting ? "Sending..." : "Send Message"}
  </button>
</form>


            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                <ul>
                <ul>
  {/* <li>
    <a href={props.data ? props.data.facebook : "/"} target="_blank" rel="noopener noreferrer">
      <i className="fa fa-facebook"></i>
    </a>
  </li> */}
  <li>
    <a href={props.data ? props.data.linkedin : "/"} target="_blank" rel="noopener noreferrer">
      <i className="fa fa-linkedin"></i>
    </a>
  </li>
</ul>

</ul>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2021 You-Turn Logistics LLC.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};
